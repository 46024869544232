import React, { Component } from 'react';

class Checkbox extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <div className={this.props.disabled ? "checkbox-wrap-v2 checkbox-wrap-v2-disabled" :  "checkbox-wrap-v2"}  onClick={(e) => {
                if (!this.props.onChange){
                    return;
                }
                let obj = {
                    target: {
                        checked: !this.props.checked
                    }
                }

                this.props.onChange(obj);
            }}>
                <div className={this.props.checked ? "checkbox-v2 checkbox-v2-checked" : "checkbox-v2"} style={{ border: this.props.error ? '1px solid #dc3545' : '1px solid #ddd'}}>
                </div>
                <span style={{ color: this.props.error ? '#dc3545' : 'black'}} className="checkbox-text">{this.props.label}</span>
            </div>
        );
    }
}

export default Checkbox;