import React from 'react'
import reactCSS from 'reactcss'
import {
    Container,
    Row,
    Col,
    FormGroup, Label,
    Input,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';

import moment from 'moment';

class Time extends React.Component {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
        // this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            isOpen: false,
            time: "00:00"
        };

    }


    handleClick = () => {
    };

    handleClose = () => {
    };

    handleChange = () => {
    };
    componentDidMount() {
        let arr1 = [];
        let arr2 = [];

        for (let i = 0; i < 24; i++) {
            let obj = {};
            if (i < 10) {
                obj = {
                    hour: ('0' + i).slice(-2)
                }
            } else {
                obj = {
                    hour: i
                }
            }

            arr1.push(obj);
        }
        for (let i = 0; i < 60; i++) {
            let obj = {};
            if (i < 10) {
                obj = {
                    minute: ('0' + i).slice(-2)
                }
            } else {
                obj = {
                    minute: i
                }
            }
            arr2.push(obj);
        }
        this.setState({ hours: arr1 });
        this.setState({ minutes: arr2 });

        document.addEventListener('mousedown', this.handleClickOutside);
        if (this.props.value) {
            this.setState({ time: this.props.value });
        }
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value != this.props.value && prevState.time == this.state.time) {
            if (this.props.value) {
                this.setState({ time: this.props.value });
            }

        }


    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ isOpen: false })
        }
    }


    render() {
        return (
            <div test={this.props.minValue + this.props.maxValue}>
                <div className="time-picker" ref={this.wrapperRef} >
                    <Input type="text"
                        disabled={this.props.disabled ? true : false}
                        onFocus={() => {
                            if (this.props.disabled) {
                                this.setState({ isOpen: false })
                            } else {
                                this.setState({ isOpen: true })
                            }
                        }} onChange={(e) => {
                            if (!this.props.disabled) {
                                let x = String(e.target.value);
                                this.setState({ time: x })
                            }

                        }}
                        style={{ height: this.props.heightInput ? this.props.heightInput : 48 }}
                        value={this.props.disabledClear && this.props.disabled ? '00:00' : this.state.time}
                    />
                    <div className={this.state.isOpen ? "dropdown" : "dropdown-close"} >
                        <div className="dropdown-item" >
                            {
                                this.state.hours && this.state.hours.map((item, idx) => {
                                    return (
                                        <button type="button" className={this.state.time && this.state.time.substring(0, 2) == item.hour ? "active" : ""} onClick={() => {
                                            let value = this.state.time.split(':');
                                            value = item.hour + ':' + value[1];

                                            this.setState({
                                                time: value
                                            }, () => {
                                                this.props.onChange(this.state.time)
                                            })

                                        }}>{item.hour}</button>
                                    )

                                })
                            }
                        </div>
                        <div className="dropdown-item">
                            {
                                this.state.minutes && this.state.minutes.map((item, idx) => {
                                    return (

                                        <button type="button" className={this.state.time && this.state.time.substring(3, 5) == item.minute ? "active" : ""} onClick={() => {
                                            let value = this.state.time.split(':');
                                            value = value[0] + ':' + item.minute;

                                            this.setState({
                                                time: value
                                            }, () => {
                                                this.props.onChange(this.state.time)
                                                this.setState({ isOpen: false })
                                            })

                                        }}>{item.minute}</button>
                                    )
                                })
                            }
                        </div>

                    </div>

                </div>
            </div>


        )
    }
}

export default Time