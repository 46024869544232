import React from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import "./App.css";

import plusIcon from "./assets/plus.png";
import minusIcon from "./assets/minus.png";
import logo from "./assets/logo.png";
import minusBlack from "./assets/minus-black.png";
import plusBlack from "./assets/plus-black.png";
import checkIcon from "./assets/check.png";

import DatePicker from './fields/datePicker'
import TimePicker from './fields/timePicker'
import Checkbox from './fields/checkbox';

import { Component } from "react";
import { th } from "date-fns/locale";

import bars from './assets/bars.svg';
import moment from "moment";

const tables = [
  (color, number, onPress) => {
    return (
      <div className="table table1" onClick={onPress}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="124"
          height="124.176"
          viewBox="0 0 124 124.176"
        >
          <defs>
            <filter
              id="Union_3"
              x="0"
              y="0"
              width="124"
              height="124.176"
              filterUnits="userSpaceOnUse"
            >
              <feOffset dy="4" input="SourceAlpha" />
              <feGaussianBlur stdDeviation="4" result="blur" />
              <feFlood flood-opacity="0.161" />
              <feComposite operator="in" in2="blur" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_3)">
            <path
              id="Union_3-2"
              data-name="Union 3"
              d="M4,103a4,4,0,0,1-4-4V6.824a4,4,0,0,1,4-4H96a4,4,0,0,1,4,4v32a4,4,0,0,1-4,4H40V99a4,4,0,0,1-4,4Z"
              transform="translate(12 5.18)"
              fill={color}
            />
          </g>
        </svg>
        <div className="table-number">{number}</div>
      </div>
    );
  },
  (color, number, onPress) => {
    return (
      <div className="table table2" onClick={onPress}>
        <svg width="124.176" height="124" viewBox="0 0 124.176 124">
          <defs>
            <filter
              id="Union_26"
              x="0"
              y="0"
              width="124.176"
              height="124"
              filterUnits="userSpaceOnUse"
            >
              <feOffset dy="4" input="SourceAlpha" />
              <feGaussianBlur stdDeviation="4" result="blur" />
              <feFlood flood-opacity="0.161" />
              <feComposite operator="in" in2="blur" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_26)">
            <path
              id="Union_26-2"
              data-name="Union 26"
              d="M4,103a4,4,0,0,1-4-4V6.824a4,4,0,0,1,4-4H96a4,4,0,0,1,4,4v32a4,4,0,0,1-4,4H40V99a4,4,0,0,1-4,4Z"
              transform="translate(9.18 108) rotate(-90)"
              fill={color}
            />
          </g>
        </svg>
        <div className="table-number">{number}</div>
      </div>
    );
  },
  (color, number, onPress) => {
    return (
      <div className="table table3" onClick={onPress}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="124.176"
          height="124"
          viewBox="0 0 124.176 124"
        >
          <defs>
            <filter
              id="Union_27"
              x="0"
              y="0"
              width="124.176"
              height="124"
              filterUnits="userSpaceOnUse"
            >
              <feOffset dy="4" input="SourceAlpha" />
              <feGaussianBlur stdDeviation="4" result="blur" />
              <feFlood flood-opacity="0.161" />
              <feComposite operator="in" in2="blur" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_27)">
            <path
              id="Union_27-2"
              data-name="Union 27"
              d="M4,103a4,4,0,0,1-4-4V6.824a4,4,0,0,1,4-4H96a4,4,0,0,1,4,4v32a4,4,0,0,1-4,4H40V99a4,4,0,0,1-4,4Z"
              transform="translate(115 8) rotate(90)"
              fill={color}
            />
          </g>
        </svg>
        <div className="table-number">{number}</div>
      </div>
    );
  },
  (color, number, onPress) => {
    return (
      <div className="table table4" onClick={onPress}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="124"
          height="124.176"
          viewBox="0 0 124 124.176"
        >
          <defs>
            <filter
              id="Union_28"
              x="0"
              y="0"
              width="124"
              height="124.176"
              filterUnits="userSpaceOnUse"
            >
              <feOffset dy="4" input="SourceAlpha" />
              <feGaussianBlur stdDeviation="4" result="blur" />
              <feFlood flood-opacity="0.161" />
              <feComposite operator="in" in2="blur" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_28)">
            <path
              id="Union_28-2"
              data-name="Union 28"
              d="M4,103a4,4,0,0,1-4-4V6.824a4,4,0,0,1,4-4H96a4,4,0,0,1,4,4v32a4,4,0,0,1-4,4H40V99a4,4,0,0,1-4,4Z"
              transform="translate(112 111) rotate(180)"
              fill={color}
            />
          </g>
        </svg>
        <div className="table-number">{number}</div>
      </div>
    );
  },

  (color, number, onPress) => {
    return (
      <div className="table table5" onClick={onPress}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="64"
          height="124"
          viewBox="0 0 64 124"
        >
          <defs>
            <filter
              id="Union_22"
              x="0"
              y="0"
              width="64"
              height="124"
              filterUnits="userSpaceOnUse"
            >
              <feOffset dy="4" input="SourceAlpha" />
              <feGaussianBlur stdDeviation="4" result="blur" />
              <feFlood flood-opacity="0.161" />
              <feComposite operator="in" in2="blur" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_22)">
            <path
              id="Union_22-2"
              data-name="Union 22"
              d="M4,0H36a4,4,0,0,1,4,4V96a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z"
              transform="translate(12 8)"
              fill={color}
            />
          </g>
        </svg>
        <div className="table-number">{number}</div>
      </div>
    );
  },

  (color, number, onPress) => {
    return (
      <div className="table table6" onClick={onPress}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="124"
          height="64"
          viewBox="0 0 124 64"
        >
          <defs>
            <filter
              id="Union_23"
              x="0"
              y="0"
              width="124"
              height="64"
              filterUnits="userSpaceOnUse"
            >
              <feOffset dy="4" input="SourceAlpha" />
              <feGaussianBlur stdDeviation="4" result="blur" />
              <feFlood flood-opacity="0.161" />
              <feComposite operator="in" in2="blur" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_23)">
            <path
              id="Union_23-2"
              data-name="Union 23"
              d="M4,0H96a4,4,0,0,1,4,4V36a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z"
              transform="translate(12 8)"
              fill={color}
            />
          </g>
        </svg>
        <div className="table-number">{number}</div>
      </div>
    );
  },

  (color, number, onPress) => {
    return (
      <div className="table table7" onClick={onPress}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="64"
          height="64"
          viewBox="0 0 64 64"
        >
          <defs>
            <filter
              id="Union_24"
              x="0"
              y="0"
              width="64"
              height="64"
              filterUnits="userSpaceOnUse"
            >
              <feOffset dy="4" input="SourceAlpha" />
              <feGaussianBlur stdDeviation="4" result="blur" />
              <feFlood flood-opacity="0.161" />
              <feComposite operator="in" in2="blur" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_24)">
            <path
              id="Union_24-2"
              data-name="Union 24"
              d="M4,0H36a4,4,0,0,1,4,4V36a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z"
              transform="translate(52 8) rotate(90)"
              fill={color}
            />
          </g>
        </svg>
        <div className="table-number">{number}</div>
      </div>
    );
  },
  (color, number, onPress) => {
    return (
      <div className="table table8" onClick={onPress}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="64"
          height="64"
          viewBox="0 0 64 64"
        >
          <defs>
            <filter
              id="Union_25"
              x="0"
              y="0"
              width="64"
              height="64"
              filterUnits="userSpaceOnUse"
            >
              <feOffset dy="4" input="SourceAlpha" />
              <feGaussianBlur stdDeviation="4" result="blur" />
              <feFlood flood-opacity="0.161" />
              <feComposite operator="in" in2="blur" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_25)">
            <path
              id="Union_25-2"
              data-name="Union 25"
              d="M20,0A20,20,0,1,1,0,20,20,20,0,0,1,20,0Z"
              transform="translate(52 8) rotate(90)"
              fill={color}
            />
          </g>
        </svg>
        <div className="table-number">{number}</div>
      </div>
    );
  },
  (color, number, onPress) => {
    return (
      <div className="table table9" onClick={onPress}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="64"
          height="64"
          viewBox="0 0 64 64"
        >
          <defs>
            <filter
              id="Union_25"
              x="0"
              y="0"
              width="64"
              height="64"
              filterUnits="userSpaceOnUse"
            >
              <feOffset dy="4" input="SourceAlpha" />
              <feGaussianBlur stdDeviation="4" result="blur" />
              <feFlood flood-opacity="0.161" />
              <feComposite operator="in" in2="blur" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_25)">
            <path
              id="Union_25-2"
              data-name="Union 25"
              d="M20,0A20,20,0,1,1,0,20,20,20,0,0,1,20,0Z"
              transform="translate(52 8) rotate(90)"
              fill={color}
            />
          </g>
        </svg>
        <div className="table-number">{number}</div>
      </div>
    );
  },
];

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tables: [],
      personNumber: 2,
      floor: 0,
      modalStep: 1,
      drinksArray: [],
      foodArray: [],
      time: '00:00',
      date: moment.unix(Math.floor(new Date().getTime()/1000)).format('DD.MM.YYYY'),
      mobileMenu: false
    };
  }
get = () => {
  fetch("https://api.kafana-banjaluka.com/tables/" + this.state.date, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          tables: result,
        });
        console.log(result)
      });
}
  componentDidMount() {
    this.get()
  }

  sendIt = () => {
    fetch("https://api.kafana-banjaluka.com/" + "reservations/create", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tableNumber: this.state.reservationModal && this.state.reservationModal.number,
        date: this.state.date,
        time: this.state.time,
        tableId: this.state.reservationModal && this.state.reservationModal._id,
        personNumber: this.state.personNumber,
        reservationType: this.state.reservationModal && this.state.reservationModal.condition && this.state.reservationModal.condition.name,
        drink: this.state.drinksArray,
        food: this.state.foodArray,
        name: this.state.name,
        phone: this.state.phone,
        mail: this.state.mail
      })
    })
    
  }

  render() {

    console.log(this.state.time)

    return (
      <div className="App">
        <div className="nav-container">
          <nav className="nav">
            <div className="container">
              <div className="nav-content">
                <div className="logo">
                  <img src={logo} />
                </div>
                <div className="bars-container">
                  <img src={bars} className="bars" onClick={() => {
                    this.setState({
                      mobileMenu: !this.state.mobileMenu
                    })
                  }} />
                </div>
                <ul className={typeof window != 'undefined' && window.innerWidth < 769 && this.state.mobileMenu ? 'nav-list nav-list-shown' : 'nav-list'}>
                  <li>
                    <a href="https://kafana-banjaluka.com/">Почетна</a>
                  </li>
                  <li>
                    <a href="https://kafana-banjaluka.com/#dogadjaji">Догађаји</a>
                  </li>
                  <li>
                    <a href="https://kafana-banjaluka.com/meni/">Мени</a>
                  </li>
                  <li>
                    <a href="https://kafana-banjaluka.com/#galerija">Галерија</a>
                  </li>
                  <li>
                    <a href="https://kafana-banjaluka.com/#o-kafani">О Кафани</a>
                  </li>
                  <li>
                    <a href="https://kafana-banjaluka.com/#kontakt">Контакт</a>
                  </li>
                  <li>
                    <button>Резервишите</button>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          <h2>Резервације</h2>

          <div className="header-row container">
            <div className="nav-tabs">
              <h6 className={this.state.floor == 0 ? 'active' : ''} onClick={() => {
                this.setState({
                  floor: 0
                })
              }}>Приземље</h6>
              <h6 className={this.state.floor == 1 ? 'active' : ''} onClick={() => {
                this.setState({
                  floor: 1
                })
              }}>Спрат</h6>
            </div>
            <div className="date">
              <DatePicker
                  label={"Datum"}
                  // style={questionCssStyle}
                  value={this.state.date.split('.')[1] + '.' +  this.state.date.split('.')[0] + '.' + this.state.date.split('.')[2] }
                  onChange={(e) => {
                    this.setState({ date: e}, this.get)  
                  }}
                />
            </div>
          </div>
        </div>

      {
        this.state.floor == 0 ?

        <div className="content-container">


      <TransformWrapper style={{ zIndex: '100' }}>
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            <button className="circle-button plus-button" onClick={() => zoomIn()}>
              <img src={plusIcon} />
            </button>
            <button className="circle-button minus-button" onClick={() => zoomOut()}>
              <img src={minusIcon} />
            </button>

            <TransformComponent 
                initialScale={1}
                minScale={0.5}
                maxScale={7} 
                style={{ zIndex: '100' }}>
              <div className="test">
              <div className="tables-area">
                {this.state.tables.map((item) => {
                  if (item.floor == this.state.floor)
                    return (
                      <div
                        style={{
                          position: "absolute",
                          top: item.position.top,
                          left: item.position.left,
                        }}
                      >
                        {tables[item.type](item.checked ? 'red' : item.reserved ? 'orange' : "#fff", item.number, () => {
                          if (!item.checked && !item.reserved)
                          this.setState({
                            reservationModal: item,
                            personNumber: item.minNumberOfPeople,
                            darkOverlay: true,
                          });
                        })}
                      </div>
                    );
                })}
                </div>
              </div>
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>

          
        </div>

        :

        null

      }

    {
        this.state.floor == 1 ?

        <div className="content-container">


      <TransformWrapper style={{ zIndex: '100' }}>
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            <button className="circle-button plus-button" onClick={() => zoomIn()}>
              <img src={plusIcon} />
            </button>
            <button className="circle-button minus-button" onClick={() => zoomOut()}>
              <img src={minusIcon} />
            </button>

            <TransformComponent 
                initialScale={1}
                minScale={0.5}
                maxScale={7} 
                style={{ zIndex: '100' }}>
              <div className="test">
              <div className="tables-area">
                {this.state.tables.map((item) => {
                  if (item.floor == this.state.floor)
                    return (
                      <div
                        style={{
                          position: "absolute",
                          top: item.position.top,
                          left: item.position.left,
                        }}
                      >
                        {tables[item.type]("#fff", item.number, () => {
                          this.setState({
                            reservationModal: item,
                            darkOverlay: true,
                          });
                        })}
                      </div>
                    );
                })}
                </div>
              </div>
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>

          
        </div>

        :

        null

      }

        <div
          className={
            this.state.reservationModal
              ? "reservation-modal open"
              : "reservation-modal"
          }
        >
          {
            this.state.modalStep == 1 ?
          <form>
            <div className="modal-header">
                <h2>
                  STO BROJ{" "}
                  {this.state.reservationModal &&
                    this.state.reservationModal.number}
                </h2>
                <span className="close-icon" onClick={() => {
                  this.setState({
                    reservationModal: false,
                    darkOverlay: false,
                    modalStep: 1
                  })
                }}>x</span>
              </div>

            <h6 style={{ marginTop: 30 }}>Vrijeme rezervacije</h6>
            <div className="reservation-time">
              <div className="reservation-time-left">

              <p className="help">Sat</p>
                <TimePicker
                  value={this.state.time}
                  onChange={(e) => {
                    this.setState({ time: e})  
                  }}
                />
                {/* <p>Datum</p>
              <select>
                <option>07.11.2019</option>
              </select> */}
                {/* <DatePicker
                  label={"Datum"}
                  // style={questionCssStyle}
                  value={this.state.date.split('.')[1] + '.' +  this.state.date.split('.')[0] + '.' + this.state.date.split('.')[2] }
                  onChange={(e) => {
                    this.setState({ date: e})  
                  }}
                /> */}
              </div>
              <div className="reservation-time-right">
                {/*<p>Sat</p>
                <select>
                  <option>20:40</option>
                </select>
                */}
                
               
               
              </div>
            </div>

            <h6 style={{ marginTop: 0 }}>Broj osoba</h6>
            <div className="reservation-nubmer">
              <div className="counter">
                <img
                  src={minusBlack}
                  className="minus"
                  onClick={() => {
                    let personNumber = this.state.personNumber;
                    if (personNumber > this.state.reservationModal.minNumberOfPeople) {
                      personNumber--;
                    }
                    this.setState({
                      personNumber,
                    });
                  }}
                />
                <input
                  type="text"
                  value={this.state.personNumber}
                  onChange={(e) => {
                    this.setState({
                      personNumber: e.target.value,
                    });
                  }}
                />
                <img
                  src={plusBlack}
                  className="plus"
                  onClick={() => {
                    this.setState({
                      personNumber: this.state.personNumber + 1,
                    });
                  }}
                />
              </div>
            </div>

            <h6 style={{ marginBottom: 0 }}>Tip rezervacije</h6>
            <h3 style={{ marginBottom: 20 }}>
              {this.state.reservationModal &&
                this.state.reservationModal.condition.name}
            </h3>

            <div className="food-container">
              <div className="drink">
                <span>Piće</span>

                <form>
                {this.state.reservationModal &&
                  this.state.reservationModal.condition.items.map(
                    (item, idx) => {
                      // console.log(item)
                      if (item.type == 2)
                        return (
                          <Checkbox label={item.name} checked={this.state.drinksArray && this.state.drinksArray.filter(el => el == item._id).length ? true : false} onChange={(e) => {
                            if(e.target.checked){
                              let drinksArray = this.state.drinksArray;
                              if(!drinksArray.filter(el => el == item._id).length){
                                drinksArray.push(item._id)
                                this.setState({ drinksArray})
                              }
                            }else{
                              let drinksArray = this.state.drinksArray;
                              let index = -1;
                              index = drinksArray.indexOf(item._id)
                              if(index > -1){
                                drinksArray.splice(index, 1)
                                this.setState({ drinksArray})
                              }
                            }
                          }}/>
                        );
                    }
                  )}
                  </form>

              </div>
              <div className="food">
                <span>Hrana</span>

                <form>
                {this.state.reservationModal &&
                  this.state.reservationModal.condition.items.map(
                    (item, idx) => {
                      if (item.type == 1)
                        return (
                          <Checkbox label={item.name} checked={this.state.foodArray && this.state.foodArray.filter(el => el == item._id).length ? true : false} onChange={(e) => {
                            if(e.target.checked){
                              let foodArray = this.state.foodArray;
                              if(!foodArray.filter(el => el == item._id).length){
                                foodArray.push(item._id)
                                this.setState({ foodArray})
                              }
                            }else{
                              let foodArray = this.state.foodArray;
                              let index = -1;
                              index = foodArray.indexOf(item._id)
                              if(index > -1){
                                foodArray.splice(index, 1)
                                this.setState({ foodArray})
                              }
                            }
                          }}/>
                        );
                    }
                  )}
                  </form>
              </div>
            </div>

            <button type="submit" className="submit-button" onClick={(e) => {
              if(this.state.foodArray.length && this.state.drinksArray.length) {
                this.setState({
                  modalStep: 2
                })
              } else {
                e.preventDefault();
                return false
              }
         
            }}>
              REZERVIŠI
            </button>
          </form>
          :
          null
          }
          {
             this.state.modalStep == 2 ?
              <form>
                <div className="modal-header">
                <h2>
                  STO BROJ{" "}
                  {this.state.reservationModal &&
                    this.state.reservationModal.number}
                </h2>
                <span className="close-icon" onClick={() => {
                  this.setState({
                    reservationModal: false,
                    darkOverlay: false,
                    modalStep: 1
                  })
                }}>x</span>
              </div>
                  <h6 style={{ marginTop: 15 }}>Podaci za rezervaciju</h6>
                  <div className="form-group">
                    <label>Ime i prezime</label>
                    <input type="text" value={this.state.name} onChange={(e) => {
                      this.setState({
                        name: e.target.value
                      })
                    }} />
                  </div>
                  <div className="form-group">
                    <label>Telefon</label>
                    <input type="text" value={this.state.phone} onChange={(e) => {
                      this.setState({
                        phone: e.target.value
                      })
                    }} />
                  </div>
                  <div className="form-group">
                    <label>E-Mail</label>
                    <input type="text" value={this.state.mail} onChange={(e) => {
                      this.setState({
                        mail: e.target.value
                      })
                      console.log(this.state.mail)
                    }} />
                  </div>

                  <button type="submit" className="submit-button" onClick={(e) => {
                    if(this.state.name == null || this.state.phone == null || this.state.mail == null) {
                      e.preventDefault();
                      return false
                    } else {
                      this.setState({
                        modalStep: 3
                      })
                    }
              
                  }}>
                    REZERVIŠI
                  </button>
              </form>
             :
             null
             
          }
          {
            this.state.modalStep == 3 ?
            <div className="last-step">
              <div className="modal-header">
                <h2>
                  STO BROJ{" "}
                  {this.state.reservationModal &&
                    this.state.reservationModal.number}
                </h2>
                <span className="close-icon" onClick={() => {
                  this.setState({
                    reservationModal: false,
                    darkOverlay: false,
                    modalStep: 1
                  })
                }}>x</span>
              </div>
              <img src={checkIcon} />
              <h5>Vaša rezervacije je primljena!</h5>
              <p>Naš tim će Vas kontaktirati kako bi potvrdili rezervaciju.</p>
              <button type="submit" style={{ letterSpacing: '0' }} className="submit-button" onClick={() => {
                    this.setState({
                      modalStep: 3
                    })
                  },
                  this.sendIt()}>
                    <a href="https://kafana-banjaluka.com/#dogadjaji">
                      POGLEDAJ DEŠAVANJA
                    </a>
                  </button>
            </div>
            :
            null
          }

        </div>

        <div
          className={
            this.state.darkOverlay ? "dark-overlay open" : "dark-overlay"
          }
          onClick={() => {
            this.setState({
              reservationModal: false,
              darkOverlay: false,
              modalStep: 1
            });
          }}
        ></div>
      </div>
    );
  }
}

export default App;
